import React from 'react';
import dynamic from 'next/dynamic';

const NotFound = dynamic(() => import('components').then((c) => c.NotFound));

export default function index() {
  return (
    <>
      <NotFound />
    </>
  );
}
